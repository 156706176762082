import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import AlertService from '@/shared/alert/alert.service';
import TranslationService from '@/locale/translation.service';
import VueI18n from 'vue-i18n';

class FormUrlBuilder {
  static ACTIONS = {
    next: 'next',
    previous: 'previous',
  };
  static baseApiUrl = 'api/form';

  static build(refForm: string, sessionId?: string, refScreen?: string, action?: string): string {
    let apiFormsUrl = `${this.baseApiUrl}/${refForm}`;
    if (sessionId) {
      apiFormsUrl += `/${sessionId}`;
    }
    if (refScreen) {
      apiFormsUrl += `/${refScreen}`;
    }
    if (action) {
      apiFormsUrl += `/${action}`;
    }
    return apiFormsUrl;
  }
}

export default class FrontFormService {
  constructor(
    private store: Store<any>,
    private router: VueRouter,
    private alertService: AlertService,
    private translationService: TranslationService,
    private i18n: VueI18n
  ) {
    this.translationService.refreshTranslation(this.store.getters.currentLanguage);
  }

  public retrieveForm(refForm: string, sessionId?: string, refScreen?: string) {
    const apiFormsUrl = FormUrlBuilder.build(refForm, sessionId, refScreen);
    return new Promise((resolve, reject) => {
      this.store.commit('referenceForm', refForm);
      axios
        .get(apiFormsUrl)
        .then(res => {
          this.loadForm(res.data, resolve)
        })
        .catch(err => {
          if (err.response.status === 403) {
            window.location = err.response.headers.location;
          }
          const message = this.i18n.t('formFront.screen.error.message');
          this.alertService.showAlert(message, 'danger');
          reject(err);
        });
    });
  }

  public nextScreen() {
    if (!this.store.getters.screenValidated) {
      const message = this.i18n.t('formFront.screen.error.message');
      this.alertService.showAlert(message, 'danger');
      return false;
    }
    this.store.commit('waitingNext', true);
    const referenceForm = this.store.getters.referenceForm;
    const sessionId = this.store.getters.sessionId;
    const referenceScreen = this.store.getters.currentScreen.reference;
    const answers = this.store.getters.validatedAnswers;
    const apiFormsUrl = FormUrlBuilder.build(referenceForm, sessionId, referenceScreen, FormUrlBuilder.ACTIONS.next);
    return new Promise((resolve, reject) => {
      axios
        .post(apiFormsUrl, answers)
        .then(res => {
          this.loadForm(res.data, resolve)
        })
        .catch(err => {
          this.store.dispatch('resetWaiting');
          const message = this.i18n.t('formFront.screen.error.message');
          this.alertService.showAlert(message, 'danger');
          reject(err);
        });
    });
  }

  public previousScreen() {
    this.store.commit('waitingPrevious', true);
    const referenceForm = this.store.getters.referenceForm;
    const sessionId = this.store.getters.sessionId;
    const referenceScreen = this.store.getters.currentScreen.reference;
    const apiFormsUrl = FormUrlBuilder.build(referenceForm, sessionId, referenceScreen, FormUrlBuilder.ACTIONS.previous);
    return new Promise((resolve, reject) => {
      axios
        .get(apiFormsUrl)
        .then(res => {
          this.loadForm(res.data, resolve)
        })
        .catch(err => {
          this.store.dispatch('resetWaiting');
          const message = this.i18n.t('formFront.screen.error.message');
          this.alertService.showAlert(message, 'danger');
          reject(err);
        });
    });
  }

  public loadForm(form, resolve) {
    this.alertService.hideAlert();
    this.store.dispatch('resetWaiting');
    this.store.commit('resetAnswers');
    this.store.dispatch('updateMeta', { form });
    this.scrollScreenTop();
    this.refreshRoute();
    resolve(true);
  }

  private scrollScreenTop() {
    window.scrollTo(0, 0);
  }

  private refreshRoute(): void {
    const referenceForm = this.store.getters.referenceForm;
    const sessionId = this.store.getters.sessionId;
    const referenceScreen = this.store.getters.currentScreen.reference;
    const currentRoute = this.router.currentRoute;
    if (currentRoute.params.referenceForm !== referenceForm || currentRoute.params.referenceScreen !== referenceScreen) {
      this.router.push(
        {
          name: 'FormFront',
          params: {
            referenceForm,
            sessionId,
            referenceScreen,
          },
        },
        route => {
          route.meta.refresh = true;
        }
      );
    }
  }
}
