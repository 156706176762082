import { Module } from 'vuex';
import ScreenService from '@/services/screen.service';

const screenService = new ScreenService();

export const formStore: Module<any, any> = {
  state: {
    idForm: 0,
    form: null,
    screenList: [],
  },
  getters: {
    idForm: state => state.idForm,
    form: state => state.form,
    screenList: state => state.screenList,
  },
  actions: {
    updateScreenIndex({ state, dispatch }, { screen, newIndex }) {
      return screenService
        .updateIndex(screen.id, newIndex)
        .then(() => {
          dispatch('loadScreens', state.idForm);
        })
    },
    loadScreens({ commit }, idForm) {
      screenService.findByFormId(idForm).then(res => {
        commit('setScreenList', res);
      });
    },
  },
  mutations: {
    setIdForm(state, idForm) {
      state.idForm = idForm;
    },
    setForm(state, form) {
      state.form = form;
    },
    setScreenList(state, screenList) {
      state.screenList = screenList;
    },
  },
};
