import { Store } from 'vuex';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import TranslationService from '@/locale/translation.service';
import AccountService from '@/services/account.service';

const filArianeBaseApiUrl = 'api/filariane';

export default class HeaderService {
  public static readonly TAB_KEY_SETTINGS = 'settings';
  public static readonly TAB_KEY_VIEW = 'view';
  public static readonly TAB_KEY_VARIABLE = 'variable';
  public static readonly TAB_KEY_RESULTS = 'results';
  public static readonly TAB_KEY_WORKSPACES = 'workspaces';
  public static readonly TAB_KEY_FORMS = 'forms';
  public static readonly TAB_KEY_USERS = 'users';

  constructor(
    private store: Store<any>,
    private accountService: AccountService,
    private translationService: TranslationService,
    private i18n: VueI18n
  ) {
    this.translationService.refreshTranslation(this.store.getters.currentLanguage);
  }

  public updateTabsForHome(currentKey: string): void {
    const tabs = [];
    if (this.accountService.hasAuthoritiesForRoute('/admin/forms')) {
      tabs.push({
        title: this.i18n.t('onglets.forms'),
        link: `/admin/forms`,
        isCurrent: currentKey === HeaderService.TAB_KEY_FORMS,
      });
    }
    if (this.accountService.hasAuthoritiesForRoute('/admin/workspaces')) {
      tabs.push({
        title: this.i18n.t('onglets.workspaces'),
        link: `/admin/workspaces`,
        isCurrent: currentKey === HeaderService.TAB_KEY_WORKSPACES,
      });
    }
    if (this.accountService.hasAuthoritiesForRoute('/admin/user')) {
      tabs.push({
        title: this.i18n.t('onglets.utilisateurs'),
        link: '/admin/user',
        isCurrent: currentKey === HeaderService.TAB_KEY_USERS,
      });
    }
    this.store.commit('tabs', tabs);
  }

  public updateTabsForWorkspaceId(workspaceId: number, currentKey: string): void {
    const tabs: any[] = [
      {
        title: this.i18n.t('onglets.parametrage'),
        link: `/admin/workspace/${workspaceId}/settings`,
        isCurrent: currentKey === HeaderService.TAB_KEY_SETTINGS,
      },
      {
        title: this.i18n.t('onglets.forms'),
        link: `/admin/workspace/${workspaceId}/view`,
        isCurrent: currentKey === HeaderService.TAB_KEY_VIEW,
      },
    ];
    this.store.commit('tabs', tabs);
  }

  public updateTabsForFormId(workspaceId: number, formId: number, currentKey: string): void {
    const tabs: any[] = [
      {
        title: this.i18n.t('onglets.parametrage'),
        link: `/admin/workspace/${workspaceId}/form/${formId}/settings`,
        isCurrent: currentKey === HeaderService.TAB_KEY_SETTINGS,
      },
      {
        title: this.i18n.t('onglets.edition'),
        link: `/admin/workspace/${workspaceId}/form/${formId}/view`,
        isCurrent: currentKey === HeaderService.TAB_KEY_VIEW,
      },
      {
        title: this.i18n.t('variable.onglets.list'),
        link: `/admin/workspace/${workspaceId}/form/${formId}/variable`,
        isCurrent: currentKey === HeaderService.TAB_KEY_VARIABLE,
      },
      {
        title: this.i18n.t('onglets.results'),
        link: `/admin/workspace/${workspaceId}/form/${formId}/results`,
        isCurrent: currentKey === HeaderService.TAB_KEY_RESULTS,
      },
    ];
    this.store.commit('tabs', tabs);
  }

  public cleanTabs(): void {
    this.store.commit('tabs', []);
  }

  public filArianeForWorkspace(id: number): void {
    axios.get(`${filArianeBaseApiUrl}/workspace/${id}`).then(res => {
      this.store.commit('filArianeElements', res.data);
    });
  }

  public filArianeForForm(id: number): void {
    axios.get(`${filArianeBaseApiUrl}/form/${id}`).then(res => {
      this.store.commit('filArianeElements', res.data);
    });
  }

  public filArianeForHome(): void {
    axios.get(`${filArianeBaseApiUrl}/home`).then(res => {
      this.store.commit('filArianeElements', res.data);
    });
  }
}
