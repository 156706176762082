import { Authority } from '@/shared/security/authority';

const UserEditComponent = () => import('@/views/user/user-edit.vue');
const UserListComponent = () => import('@/views/user/user-list.vue');

export default [
  {
    path: '/admin/user/:idUser/edit',
    name: 'UserEditComponent',
    component: UserEditComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_ORGANIZATION] },
  },
  {
    path: '/admin/user/creation',
    name: 'UserCreateComponent',
    component: UserEditComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_ORGANIZATION] },
  },
  {
    path: '/admin/user',
    name: 'UserListComponent',
    component: UserListComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_ORGANIZATION] },
  },
];
