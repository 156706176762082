import ConditionsService from '@/services/conditions.service';
import { Module } from 'vuex';

const conditionsService = new ConditionsService();

export const screenStore: Module<any, any> = {
  state: {
    screen: {},
    questions: [],
    variables: [],
    operators: [
      {
        value: '>=',
        label: '⩾',
      },
      {
        value: '>',
        label: '>',
      },
      {
        value: '<=',
        label: '⩽',
      },
      {
        value: '<',
        label: '<',
      },
      {
        value: '==',
        label: '=',
      },
      {
        value: '!=',
        label: '≠',
      },
    ]
  },
  getters: {
    idScreen: state => state.screen.id,
    indexScreen: state => state.screen.index,
    screen: state => state.screen,
    questions: state => state.questions,
    variables: state => state.variables,
    operators: state => state.operators,
  },
  actions: {
    loadScreen({ commit, dispatch, getters }, screen) {
      commit('setScreen', screen);
      // Demande du rechargement des écrans pour mettre à jour la liste des écrans.
      dispatch('loadScreens', getters.idForm);
      // Demande du rechargement des questions disponibles pour les écrans précédents
      dispatch('loadQuestions', { idForm: getters.idForm, indexScreen: getters.indexScreen });
    },
    unloadScreen({ commit, dispatch, getters }) {
      commit('unsetScreen');
      dispatch('loadScreens', getters.idForm);
    },
    loadQuestions({ commit, getters }, payload) {
      conditionsService
        .retrieveExpressionElement(payload.idForm, payload.indexScreen)
        .then(res => {
          const questions = conditionsService.loadScreensQuestions(res.screens);
          commit('setQuestions', questions);
          commit('setVariables', res.variables);
        })
        .catch(console.error);
    },
  },
  mutations: {
    setScreen(state, screen) {
      state.screen = screen;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setVariables(state, variables) {
      state.variables = variables;
    },
    unsetScreen(state) {
      state.screen = {};
      state.questions = [];
      state.variables = [];
    },
  },
};
