import { Authority } from '@/shared/security/authority';
import { PanelUtils } from '@/components/panel/panel.utils';

const FormCreationComponent = () => import('@/views/form/form-creation.vue');
const FormDuplicationComponent = () => import('@/views/form/form-duplication.vue');
const FormPreviewComponent = () => import('@/views/form/form-preview.vue');
const FormResultsComponent = () => import('@/views/form/form-results.vue');
const FormSettingsComponent = () => import('@/views/form/form-settings.vue');
const FormShareComponent = () => import('@/views/form/form-share.vue');
const FormListComponent = () => import('@/views/form/form-list.vue');
const FormVariablesComponent = () => import('@/views/form/form-variables.vue');

export default [
  {
    path: '/admin/workspace/:idWorkspace/form/creation',
    name: 'FormCreationComponent',
    component: FormCreationComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM] },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/duplication',
    name: 'FormDuplicationComponent',
    component: FormDuplicationComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM] },
  },
  {
    // TODO : Non supporté par l'application actuellement.
    path: '/admin/form/:idForm/preview',
    name: 'FormPreviewComponent',
    component: FormPreviewComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/results',
    name: 'FormResultsComponent',
    component: FormResultsComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.VIEW_RESULT] },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/settings',
    name: 'FormSettingsComponent',
    component: FormSettingsComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_WORKSPACE],
      panel: PanelUtils.PANEL_FORM,
    },
  },
  {
    // TODO : Non supporté par l'application actuellement.
    path: '/admin/form/:idForm/share',
    name: 'FormShareComponent',
    component: FormShareComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/forms',
    name: 'FormListComponent',
    component: FormListComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.VIEW_FORM] },
  },
  {
    path: '/admin/form/:idForm/variables',
    name: 'FormVariablesComponent',
    component: FormVariablesComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM] },
  },
];
