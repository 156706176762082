import Vue from 'vue';
import Meta from 'vue-meta';

Vue.use(Meta);

export const metaInfo = {
  title: 'Aplly',
  titleTemplate: '%s',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'my standard description',
    },
  ],
};
