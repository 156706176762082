import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';

export default class AccountService {
  constructor(private store: Store<any>, private translationService: TranslationService, private router: VueRouter) {}

  public retrieveAccount(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get('api/account')
        .then(response => {
          this.store.commit('authenticate');
          const account = response.data;
          if (account) {
            this.store.commit('authenticated', account);
            if (this.store.getters.currentLanguage !== account.langKey) {
              this.store.commit('currentLanguage', account.langKey);
            }
            if (sessionStorage.getItem('requested-url')) {
              this.router.replace(sessionStorage.getItem('requested-url'));
              sessionStorage.removeItem('requested-url');
            }
          } else {
            this.store.commit('logout');
            this.router.push('/');
            sessionStorage.removeItem('requested-url');
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(() => {
          this.store.commit('logout');
          resolve(false);
        });
    });
  }

  public checkAuth(): Promise<boolean> {
    if (!this.store.getters.account && !this.store.getters.logon && this.token) {
      return this.retrieveAccount();
    } else {
      return new Promise(resolve => {
        resolve(false);
      });
    }
  }

  public hasAnyAuthorityAndCheckAuth(authorities: any): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.token || !this.authenticated || !this.userAuthorities) {
      return this.checkAuth().then(connected => {
        if (connected) {
          return this.checkAuthority(authorities);
        } else {
          return new Promise(resolve => {
            resolve(false);
          });
        }
      });
    } else {
      return this.checkAuthority(authorities);
    }
  }

  private checkAuthority(authorities: any): Promise<boolean> {
    for (let i = 0; i < authorities.length; i++) {
      if (this.userAuthorities.includes(authorities[i])) {
        return new Promise(resolve => {
          resolve(true);
        });
      }
    }
    return new Promise(resolve => {
      resolve(false);
    });
  }

  public retrieveUsersHome(): Promise<any> {
    return new Promise(resolve => axios.get(`api/filariane/user/home`).then(value => resolve(value.data)));
  }

  public logout(): void {
    this.store.dispatch('logout', () => this.router.push('/login'));
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): any {
    return this.store.getters.account.authorities;
  }

  public get token(): any {
    return localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken');
  }

  public hasAuthoritiesForRoute(path: string): boolean {
    if (this.router.getRoutes().find(r => r.path === path) && !this.router.getRoutes().find(r => r.path === path).meta.authorities) {
      return true;
    }

    if (this.store.getters.account) {
      for (const authority of this.userAuthorities) {
        if (
          this.router
            .getRoutes()
            .find(r => r.path === path)
            ?.meta?.authorities?.includes(authority)
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
