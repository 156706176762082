import { ROUTES } from '@/router/routes-constants';

const Login = () => import('@/views/account/login/login.vue');
const Logout = () => import('@/views/account/logout/logout.vue');
const ResetPasswordInit = () => import('@/views/account/reset-password/init/reset-password-init.vue');
const ResetPasswordFinish = () => import('@/views/account/reset-password/finish/reset-password-finish.vue');

export default [
  {
    path: ROUTES.login.path,
    name: ROUTES.login.name,
    component: Login,
  },
  {
    path: ROUTES.logout.path,
    name: ROUTES.logout.name,
    component: Logout,
  },
  {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    component: ResetPasswordFinish,
  },
];
