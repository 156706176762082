var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.alertDisplay
    ? _c(
        "div",
        {
          staticClass: "alert alert-danger alert-dismissible fade",
          attrs: { role: "alert" }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.alertMessage))]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { "aria-label": "Close", type: "button" },
              on: { click: _vm.hideAlert }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }