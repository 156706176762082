import { Module } from 'vuex';

export const headerStore: Module<any, any> = {
  state: {
    tabs: [],
    filArianeElements: [],
  },
  getters: {
    tabs: state => state.tabs,
    filArianeElements: state => state.filArianeElements,
  },
  mutations: {
    tabs(state, newTabs) {
      state.tabs = newTabs;
    },
    filArianeElements(state, newFilArianeElements) {
      state.filArianeElements = newFilArianeElements;
    },
  },
};
