import axios from 'axios';

import { IScreen } from '@/shared/model/screen.model';

const baseApiUrl = 'api/screens';

export default class ScreenService {
  public find(id: number): Promise<IScreen> {
    return new Promise<IScreen>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByFormId(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/forms/${id}/screens`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveFirstScreenOfForm(formId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/forms/${formId}/firstscreen`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IScreen): Promise<IScreen> {
    return new Promise<IScreen>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IScreen): Promise<IScreen> {
    return new Promise<IScreen>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateIndex(id: number, index: number): Promise<IScreen> {
    return new Promise<IScreen>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${id}`, [{ op: 'replace', path: '/index', value: index }], {
          headers: { 'Content-Type': 'application/json-patch+json' },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
