import frontForm from '@/router/front-form';

import { ROUTES } from '@/router/routes-constants';

const Error = () => import('@/core/error/error.vue');
const Front = () => import('@/front/front.vue');
const Home = () => import('@/front/home/home.vue');

export default [
  {
    path: ROUTES.home.path,
    component: Front,
    children: [
      {
        path: ROUTES.home.path,
        name: ROUTES.home.name,
        component: Home,
      },
      {
        path: ROUTES.forbidden.path,
        name: ROUTES.forbidden.name,
        component: Error,
        meta: { error403: true },
      },
      {
        path: ROUTES.notFound.path,
        name: ROUTES.notFound.name,
        component: Error,
        meta: { error404: true },
      },
      ...frontForm,
    ],
  },
];
