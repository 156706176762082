import Vuex from 'vuex';
import VueI18n, { DateTimeFormats } from 'vue-i18n';
import JhiFormatter from './formatter';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import VueCookie from 'vue-cookie';
import Vue2Filters from 'vue2-filters';

import { formStore } from '@/shared/config/store/admin/form-store';
import { screenStore } from '@/shared/config/store/admin/screen-store';
import { workspaceStore } from '@/shared/config/store/admin/workspace-store';
import { accountStore } from '@/shared/config/store/common/account-store';
import { alertStore } from '@/shared/config/store/common/alert-store';
import { translationStore } from '@/shared/config/store/common/translation-store';
import { frontFormStore } from '@/shared/config/store/front/front-form.store';
import { headerStore } from '@/shared/config/store/common/header-store';

const dateTimeFormats: DateTimeFormats = {
  fr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vue2Filters);
  setupAxiosInterceptors(() => console.log('Unauthorized!'));
}

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
  });
}

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      formStore,
      screenStore,
      workspaceStore,
      accountStore,
      alertStore,
      translationStore,
      frontFormStore,
      headerStore,
    },
  });
}
