import { Module } from 'vuex';

export const workspaceStore: Module<any, any> = {
  state: {
    idWorkspace: 0,
  },
  getters: {
    idWorkspace: state => state.idWorkspace,
  },
  actions: {},
  mutations: {
    setIdWorkspace(state, idWorkspace) {
      state.idWorkspace = idWorkspace;
    },
  },
};
