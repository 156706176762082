import { Authority } from '@/shared/security/authority';

const VariableEditComponent = () => import('@/views/variable/variable-edit.vue');
const VariableListComponent = () => import('@/views/variable/variable-list.vue');

export default [
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/variable/:idVariable/edit',
    name: 'VariableEditComponent',
    component: VariableEditComponent,
    meta: { authorities: [Authority.EDIT_FORM] },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/variable',
    name: 'VariableListComponent',
    component: VariableListComponent,
    meta: { authorities: [Authority.EDIT_FORM] },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/variable/creation',
    name: 'VariableCreateComponent',
    component: VariableEditComponent,
    meta: { authorities: [Authority.EDIT_FORM] },
  },
];
