import { Authority } from '@/shared/security/authority';
import { PanelUtils } from '@/components/panel/panel.utils';

const ScreenCreateComponent = () => import('@/views/screen/screen-create.vue');
const ScreenEditComponent = () => import('@/views/screen/screen-edit.vue');
const ScreenSettingsComponent = () => import('@/views/screen/screen-settings.vue');

export default [
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/screen/create',
    name: 'ScreenCreateComponent',
    component: ScreenCreateComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM],
      panel: PanelUtils.PANEL_SCREEN,
    },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/screen/:idScreen/edit',
    name: 'ScreenEditComponent',
    component: ScreenEditComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM],
      panel: PanelUtils.PANEL_SCREEN,
    },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/screen/:idScreen/settings',
    name: 'ScreenSettingsComponent',
    component: ScreenSettingsComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM],
      panel: PanelUtils.PANEL_SCREEN,
    },
  },
  {
    path: '/admin/workspace/:idWorkspace/form/:idForm/view',
    name: 'FirstScreenEditComponent',
    component: ScreenEditComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_FORM],
      panel: PanelUtils.PANEL_SCREEN,
    },
  },
];
