import { IScreen } from '@/shared/model/screen.model';
import axios from 'axios';

export const ConditionTypes = {
  HAS_ANSWER: 'hasAnswer',
  HAS_NO_ANSWER: 'hasNoAnswer',
  ANSWERS: 'answers',
  COMPARISON: 'comparison',
  ADVANCED: 'advanced',
  RANGE: 'range',
};

export default class ConditionsService {
  public retrieveExpressionElement(idForm: number, idxScreen: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/form/${idForm}/screen/${idxScreen}/expression/element`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public loadScreensQuestions(screens: any) {
    return screens.flatMap((screen: IScreen) => {
      const itemsList = [];
      screen.itemsList.forEach(item => {
        Object.assign(item, { idxScreen: screen.index, screenReference: screen.reference });
        itemsList.push(item);
      });
      return itemsList;
    });
  }

  public parseItemConditions(item: any) {
    if (item.displayConditionSimple.displayConditions.length === 0) {
      return [];
    }
    const displayConditions = [];
    try {
      item.displayConditionSimple.displayConditions.forEach((condition: any) => {
        // à voir si ceux qui sont à push dans l'Array directement
        // et ceux qui sont à traiter à part, comme c'est fait pour l'advanced condition
        if (condition.type === ConditionTypes.ANSWERS) {
          displayConditions.push(condition);
        } else if (condition.type === ConditionTypes.HAS_ANSWER) {
          displayConditions.push(condition);
        } else if (condition.type === ConditionTypes.HAS_NO_ANSWER) {
          displayConditions.push(condition);
        } else if (condition.type === ConditionTypes.COMPARISON) {
          displayConditions.push(condition);
        } else if (condition.type === ConditionTypes.RANGE) {
          displayConditions.push(condition);
        } else {
          // Si une des conditions n'est pas gérée, on sort de la boucle en erreur
          throw 'Unknown condition for item "' + item.reference + '" : ' + JSON.stringify(condition);
        }
      });
    } catch (e) {
      console.error(e);
      // Lorsqu'on est sorti en erreur, on retourne une condition en mode avancée
      return this.defineAvancedCondition(item);
    }
    return displayConditions;
  }

  private defineAvancedCondition(item) {
    return [
      {
        type: ConditionTypes.ADVANCED,
        condition: item.displayCondition,
      },
    ];
  }
}
