// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import router from './router';
import { metaInfo } from './shared/meta';
import * as config from './shared/config/config';

import AccountService from './services/account.service';
import AlertService from '@/shared/alert/alert.service';
import TranslationService from '@/locale/translation.service';
import FrontFormService from '@/front/services/front-form.service';
import { ROUTES } from '@/router/routes-constants';
import HeaderService from '@/shared/service/header-service';

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
Vue.use(Vue2Filters);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const alertService = new AlertService(store);
const translationService = new TranslationService(store, i18n);
const accountService = new AccountService(store, translationService, router);
const headerService = new HeaderService(store, accountService, translationService, i18n);
const frontFormService = new FrontFormService(store, router, alertService, translationService, i18n);

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next(ROUTES.notFound.path);
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    // si la page demandée est soumise à des droits,
    // on vérifie si l'utilisateur est autorisé à la consulter
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        // s'il n'est pas autorisé ou pas connecté, on sauvegarde l'url et on redirige sur la page d'accueil
        sessionStorage.setItem('requested-url', to.fullPath);
        next(ROUTES.login.path);
      } else {
        // s'il est autorisé et connecté, on continue
        next();
      }
    });
  } else if (to.fullPath === ROUTES.login.path) {
    // si la page demandée est la page de login (non soumise aux droits)
    accountService.checkAuth().then(value => {
      // si l'utilisateur est déjà connecté, on redirige vers la page d'accueil
      if (value) {
        accountService.retrieveUsersHome().then(homePath => next(homePath));
      } else {
        // sinon on continue vers la page de login
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  metaInfo,
  provide: {
    alertService: () => alertService,
    translationService: () => translationService,
    accountService: () => accountService,
    frontFormService: () => frontFormService,
    headerService: () => headerService,
  },
  i18n,
  store,
});
