import Vue from 'vue';
import Component from 'vue-class-component';
import Alert from '@/shared/alert/alert.vue';

@Component({
  components: {
    Alert,
  },
})
export default class App extends Vue {}
