import { Authority } from '@/shared/security/authority';

const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiAuditsComponent = () => import('@/admin/audits/audits.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
/**
 * Comporte différentes pages d'administration générées par Jhipster.
 * Elles ne sont plus supportées, mais elles sont conservées car elles peuvent rester utiles afin d'avoir des statistiques sur l'application.
 */
export default [
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/audits',
    name: 'JhiAuditsComponent',
    component: JhiAuditsComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/jhi-health',
    name: 'JhiHealthComponent',
    component: JhiHealthComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/logs',
    name: 'JhiLogsComponent',
    component: JhiLogsComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/jhi-metrics',
    name: 'JhiMetricsComponent',
    component: JhiMetricsComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
  {
    path: '/admin/jhi-configuration',
    name: 'JhiConfigurationComponent',
    component: JhiConfigurationComponent,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
];
