import { Authority } from '@/shared/security/authority';
import { PanelUtils } from '@/components/panel/panel.utils';

const WorkspaceCreationComponent = () => import('@/views/workspace/workspace-creation.vue');
const WorkspaceSettingsComponent = () => import('@/views/workspace/workspace-settings.vue');
const WorkspaceListComponent = () => import('@/views/workspace/workspace-list.vue');
const WorkspaceViewComponent = () => import('@/views/workspace/workspace-view.vue');

export default [
  {
    path: '/admin/workspace/creation',
    name: 'WorkspaceCreationComponent',
    component: WorkspaceCreationComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.EDIT_ORGANIZATION] },
  },
  {
    path: '/admin/workspace/:idWorkspace/settings',
    name: 'WorkspaceSettingsComponent',
    component: WorkspaceSettingsComponent,
    meta: {
      authorities: [Authority.ADMIN_SAAS, Authority.EDIT_WORKSPACE],
      panel: PanelUtils.PANEL_WORKSPACE,
    },
  },
  {
    path: '/admin/workspaces',
    name: 'WorkspaceListComponent',
    component: WorkspaceListComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.VIEW_WORKSPACE] },
  },
  {
    path: '/admin/workspace/:idWorkspace/view',
    name: 'WorkspaceViewComponent',
    component: WorkspaceViewComponent,
    meta: { authorities: [Authority.ADMIN_SAAS, Authority.VIEW_WORKSPACE] },
  },
];
