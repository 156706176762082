import { Module } from 'vuex';
import { IAnswerFront } from '@/front/model/answer-front.model';

export const frontFormStore: Module<any, any> = {
  state: {
    sessionId: '',
    referenceForm: '',
    titleForm: '',
    titleScreen: '',
    descriptionForm: '',
    progress: null,
    currentScreen: null,
    answers: [],
    waitingPrevious: false,
    waitingNext: false,
  },
  getters: {
    screenValidated: state => {
      const validated = state.answers.filter(a => a.validate());
      return validated.length === state.answers.length;
    },
    sessionId: state => state.sessionId,
    referenceForm: state => state.referenceForm,
    titleForm: state => state.titleForm,
    titleScreen: state => state.titleScreen,
    descriptionForm: state => state.descriptionForm,
    progress: state => state.progress,
    currentScreen: state => state.currentScreen,
    validatedAnswers: state => {
      return state.answers.filter(a => a.answer.length > 0);
    },
    waitingPrevious: state => {
      return state.waitingPrevious;
    },
    waitingNext: state => {
      return state.waitingNext;
    },
  },
  actions: {
    updateMeta({ commit }, { form }) {
      commit('sessionId', form.sessionId);
      commit('titleForm', form.title);
      commit('titleScreen', form.screen.title);
      commit('descriptionForm', form.description);
      commit('progress', form.progress);
      commit('currentScreen', form.screen);
    },
    resetWaiting({ commit }) {
      commit('waitingPrevious', false);
      commit('waitingNext', false);
    },
  },
  mutations: {
    referenceForm(state, referenceForm) {
      state.referenceForm = referenceForm;
    },
    sessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    titleForm(state, titleForm) {
      state.titleForm = titleForm || '';
    },
    titleScreen(state, titleScreen) {
      state.titleScreen = titleScreen || '';
    },
    descriptionForm(state, descriptionForm) {
      state.descriptionForm = descriptionForm || '';
    },
    currentScreen(state, currentScreen) {
      state.currentScreen = currentScreen;
    },
    progress(state, progress) {
      state.progress = progress;
    },
    resetAnswers(state) {
      state.answers = [];
      state.screenValidated = true;
    },
    addAnswer(state, answer: IAnswerFront) {
      state.answers.push(answer);
    },
    updateAnswer(state, { questionReference, answer }) {
      const index = state.answers.findIndex(a => a.questionReference === questionReference);
      state.answers[index].answer = answer;
    },
    waitingPrevious(state, waitingPrevious) {
      state.waitingPrevious = waitingPrevious;
    },
    waitingNext(state, waitingNext) {
      state.waitingNext = waitingNext;
    },
  },
};
