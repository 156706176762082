import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';

import account from '@/router/account';
import admin from '@/router/admin';
import front from '@/router/front';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

Vue.use(Router);

// prettier-ignore
export default new Router({
  mode: 'history',
  routes: [
    ...account,
    ...admin,
    ...front,
  ]
});
