export enum Authority {
  ADMIN_SAAS = 'ADMIN_SAAS',
  VIEW_WORKSPACE = 'VIEW_WORKSPACE',
  VIEW_RESULT = 'VIEW_RESULT',
  VIEW_ORGANIZATION = 'VIEW_ORGANIZATION',
  EDIT_WORKSPACE = 'EDIT_WORKSPACE',
  VIEW_FORM = 'VIEW_FORM',
  EDIT_FORM = 'EDIT_FORM',
  EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
  // TODO : utilisé pour les tests mais dans l'idée ne sert a rien.
  USER = 'ROLE_USER',
}
