import { Authority } from '@/shared/security/authority';
import { ROUTES } from '@/router/routes-constants';

import adminAccount from '@/router/admin-account';
import adminForm from '@/router/admin-form';
import adminJhi from '@/router/admin-jhi';
import adminScreen from '@/router/admin-screen';
import adminUser from '@/router/admin-user';
import adminVariable from '@/router/admin-variable';
import adminWorkspace from '@/router/admin-workspace';

const Admin = () => import('@/admin/admin.vue');

export default [
  {
    path: ROUTES.admin.path,
    component: Admin,
    children: [
      {
        path: '',
        redirect: '/admin/forms',
        meta: { authorities: [Authority.ADMIN_SAAS, Authority.VIEW_WORKSPACE] },
      },
      ...adminAccount,
      ...adminForm,
      ...adminJhi,
      ...adminScreen,
      ...adminUser,
      ...adminVariable,
      ...adminWorkspace,
    ],
  },
];
