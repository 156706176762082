import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import AlertMixin from '@/shared/alert/alert.mixin';

@Component
export default class Alert extends mixins(AlertMixin) {
  @Watch('$store.getters.alertDisplay')
  public refreshAlert() {
    this.getAlertFromStore();
  }
}
