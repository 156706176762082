import { Authority } from '@/shared/security/authority';

const Settings = () => import('@/views/account/settings/settings.vue');

export default [
  {
    path: '/admin/account/settings',
    name: 'Settings',
    component: Settings,
    meta: { authorities: [Authority.ADMIN_SAAS] },
  },
];
