export const ROUTES = {
  home: {
    path: '/',
    name: 'Home',
  },
  login: {
    path: '/login',
    name: 'Login',
  },
  logout: {
    path: '/logout',
    name: 'Logout',
  },
  notFound: {
    path: '/not-found',
    name: 'NotFound',
  },
  forbidden: {
    path: '/forbidden',
    name: 'Forbidden',
  },
  admin: {
    path: '/admin',
    name: 'AdminHome',
  },
};
